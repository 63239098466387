import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ProfessionalServiceAgreement from "./../../components/professional-service-agreement";
import TermsMenu from "./../../components/terms-menu";
import AgreementBanner from "./../../components/agreement-banner";

const IndexPage = () => (
  <>
    <Layout>
      <SEO
        title="Terms Of Service"
        keywords={[
          `web hosting`,
          `domain names`,
          `web site`,
          `search engine optimization`,
          `hosting`,
          `servers`,
        ]}
        pageType={"company_info"}
        flowType={"none"}
      />
      <TermsMenu />
      <ProfessionalServiceAgreement />
    </Layout>
    {/* <AgreementBanner /> */}
  </>
);

export default IndexPage;
