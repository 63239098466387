import { Link } from 'gatsby'
import styled from 'styled-components'
import ArrowForward from '@material-ui/icons/ArrowForwardIos'

import Button from '@eig-builder/core-ui/Button'

const Banner = styled.div`
  background-color: ${ ({ isWhite }) => (isWhite ? 'white' : '#0076DF') };
  color: ${ ({ isWhite }) => (isWhite ? '#0076DF' : 'white') };
  width: 100%;
  font-size: 1em;  
  position: fixed;
  bottom: 0;
`
Banner.buttonContainer = styled.div`
  width: 100%;
  @media (min-width: 576px) {
    width: auto;
  }
`
Banner.Link = styled(Link)`
  flex-grow: 1;
  display: inline-block;
  color: ${ ({ isWhite }) => (isWhite ? '0076DF' : 'white') };
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
`

Banner.arrow = styled(ArrowForward)`
  display: inline-block;
  margin-left: 5px;
`

Banner.Close = styled(Button)`
  span {
    font-size: 24pt;
    font-weight: 100;
    line-height: 0.8em;
  }
`

export default Banner
